import { debounce } from '../utils/debounce';

export const BREADCRUMB_CONTAINER = 'data-breadcrumb';
const START_HIDDEN_CLASS = 'start-hidden';
const END_HIDDEN_CLASS = 'end-hidden';

export class Breadcrumb {
  private breadcrumbContainerElement: HTMLElement;
  private breadcrumbElement: HTMLElement;
  private breadcrumbScrollHandler!: (event: Event) => void;
  private windowResizeHandler!: () => void;

  constructor(breadcrumbContainerElement: HTMLElement) {
    this.breadcrumbScrollHandler = this.handleBreadcrumbScroll.bind(this);
    this.windowResizeHandler = this.resetScrollPosition.bind(this);

    this.breadcrumbContainerElement = breadcrumbContainerElement;
    this.breadcrumbElement = breadcrumbContainerElement?.querySelector('.breadcrumb') as HTMLElement;

    if (breadcrumbContainerElement != null && this.breadcrumbElement != null) {
      this.initEvents();

      setTimeout(() => {
        this.resetScrollPosition();
        this.calculateClasses();
      }, 0);
    }
  }

  private initEvents() {
    this.breadcrumbElement.addEventListener('scroll', this.breadcrumbScrollHandler);

    window.addEventListener('resize', debounce(this.windowResizeHandler, 50));
  }

  private handleBreadcrumbScroll() {
    this.calculateClasses();
  }

  private resetScrollPosition() {
    this.breadcrumbElement.scrollLeft = this.breadcrumbElement.scrollWidth - this.breadcrumbElement.offsetWidth;
  }

  private calculateClasses() {
    const containerElement = this.breadcrumbContainerElement;

    if (this.breadcrumbElement.scrollLeft <= 1 && containerElement.classList.contains(START_HIDDEN_CLASS)) {
      containerElement.classList.remove(START_HIDDEN_CLASS);
    } else if (
      this.breadcrumbElement.scrollLeft > 0 &&
      containerElement.classList.contains(START_HIDDEN_CLASS) === false
    ) {
      containerElement.classList.add(START_HIDDEN_CLASS);
    }

    const maxLeftPosition = this.breadcrumbElement.scrollWidth - this.breadcrumbElement.offsetWidth - 1;

    if (this.breadcrumbElement.scrollLeft >= maxLeftPosition && containerElement.classList.contains(END_HIDDEN_CLASS)) {
      containerElement.classList.remove(END_HIDDEN_CLASS);
    } else if (
      this.breadcrumbElement.scrollLeft < maxLeftPosition &&
      containerElement.classList.contains(END_HIDDEN_CLASS) === false
    ) {
      containerElement.classList.add(END_HIDDEN_CLASS);
    }
  }
}
