export function debounce(fn: Function, delay: number) {
  let timeoutId: NodeJS.Timeout;

  return function(...args: unknown[]) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}
