import { debounce } from '../utils/debounce';

export const SLIDER_DATA_CONTAINER = 'data-napier-slider';
const SLIDER_TRANSITION_OFFSET = 600;

export class Slider {
  private sliderContainerElement: HTMLElement;
  private slideElements: HTMLLIElement[] = [];
  private slideElementsLinks: HTMLAnchorElement[] = [];
  private currentElementIndex: number = 0;
  private linkElementClickHandler!: (event: MouseEvent) => void;
  private linkWidthUpdateHandler!: () => void;
  private mouseDownHandler!: (event: MouseEvent | TouchEvent) => void;
  private mouseoUpHandler!: () => void;
  private mouseoMoveHandler!: (event: MouseEvent | TouchEvent) => void;
  private isMoving = false;
  private initialXPosition = 0;

  constructor(sliderContainerElement: HTMLElement) {
    this.linkElementClickHandler = this.handleLinkElementClick.bind(this);
    this.linkWidthUpdateHandler = this.updateLinkElementsWidth.bind(this);
    this.mouseDownHandler = this.onContainerMouseDown.bind(this);
    this.mouseoUpHandler = this.onContainerMouseUp.bind(this);
    this.mouseoMoveHandler = this.onContainerMouseMove.bind(this);

    this.sliderContainerElement = sliderContainerElement;

    if (sliderContainerElement != null) {
      this.slideElements = Array.from(sliderContainerElement.querySelectorAll('li'));
      this.slideElementsLinks = Array.from(sliderContainerElement.querySelectorAll('.item-container'));

      this.initEvents();

      setTimeout(() => {
        this.updateLinkElementsWidth();
        this.updateCurrentElementIndex();

        setTimeout(() => {
          this.sliderLoaded();
        }, 0);
      }, 0);
    }
  }

  private initEvents() {
    this.slideElementsLinks.forEach(linkElement => {
      linkElement.addEventListener('click', this.linkElementClickHandler);
    });

    this.sliderContainerElement.addEventListener('mousedown', this.mouseDownHandler);
    this.sliderContainerElement.addEventListener('touchstart', this.mouseDownHandler);
    this.sliderContainerElement.addEventListener('mouseup', this.mouseoUpHandler);
    this.sliderContainerElement.addEventListener('touchend', this.mouseoUpHandler);
    this.sliderContainerElement.addEventListener('mousemove', this.mouseoMoveHandler);
    this.sliderContainerElement.addEventListener('touchmove', this.mouseoMoveHandler);

    window.addEventListener('resize', debounce(this.linkWidthUpdateHandler, 50));
  }

  private sliderLoaded(): void {
    this.sliderContainerElement.classList.add('loaded');
  }

  private handleLinkElementClick(event: MouseEvent): void {
    event.preventDefault();

    const targetLinkElement = (event.target as HTMLElement)?.parentNode;
    const newElementIndex = this.slideElementsLinks.findIndex(linkElement => linkElement === targetLinkElement);

    if (newElementIndex > -1) {
      this.setCurrentSlide(newElementIndex);
    }
  }

  private setCurrentSlide(slideIndex: number): void {
    this.slideElements.forEach(slideElement => {
      slideElement.classList.remove('current');
    });

    this.slideElements[slideIndex]?.classList.add('current');

    setTimeout(() => {
      this.updateCurrentElementIndex();
    }, 0);

    setTimeout(() => {
      window.location.href = this.slideElementsLinks[this.currentElementIndex]?.href;
    }, SLIDER_TRANSITION_OFFSET);
  }

  private updateLinkElementsWidth(): void {
    const containerRects = this.sliderContainerElement.getBoundingClientRect();
    const newWidth = `calc(${containerRects.width}px - ${2 * (this.slideElements.length - 1)}rem)`;

    this.slideElementsLinks.forEach(linkElement => (linkElement.style.width = newWidth));
  }

  private updateCurrentElementIndex(): void {
    this.currentElementIndex = this.slideElements.findIndex(slideElement => slideElement.classList.contains('current'));
  }

  private onContainerMouseDown(event: MouseEvent | TouchEvent): void {
    const eventTarget = (event as TouchEvent).changedTouches ? (event as TouchEvent).changedTouches[0] : event;

    this.isMoving = true;
    this.initialXPosition = (eventTarget as Touch).clientX;
  }

  private onContainerMouseUp(): void {
    this.isMoving = false;
    this.initialXPosition = 0;
  }

  private onContainerMouseMove(event: MouseEvent | TouchEvent): void {
    if (this.isMoving === false) {
      return;
    }

    const eventTarget = (event as TouchEvent).changedTouches ? (event as TouchEvent).changedTouches[0] : event;

    event.preventDefault();

    const positionDifference = (eventTarget as Touch).clientX - this.initialXPosition;
    const moveDirection = Math.sign(positionDifference);

    if (moveDirection === 1 && this.currentElementIndex !== 0) {
      this.setCurrentSlide(this.currentElementIndex - 1);
      this.isMoving = false;
    } else if (moveDirection === -1 && this.currentElementIndex !== this.slideElements.length - 1) {
      this.setCurrentSlide(this.currentElementIndex + 1);
      this.isMoving = false;
    }
  }
}
