import '../styles/index.scss';
import { Breadcrumb, BREADCRUMB_CONTAINER } from './components/breadcrumb';
import { Slider, SLIDER_DATA_CONTAINER } from './components/slider';
import { VideoModal, VIDEO_MODAL_DATA_TOGGLE } from './components/video-modal';

if (process.env.NODE_ENV === 'development') {
  require('../index.html');
  require('../health.html');
  require('../environment.html');
  require('../copyright-policy.html');
  require('../communities.html');
  require('../advanced-tech.html');
}

function initVideoModals() {
  const videoElements = document.querySelectorAll(`[${VIDEO_MODAL_DATA_TOGGLE}]`);

  videoElements.forEach(videoElement => {
    new VideoModal(videoElement as HTMLElement);
  });
}

function initSliders() {
  const sliderElements = document.querySelectorAll(`[${SLIDER_DATA_CONTAINER}]`);

  sliderElements.forEach(sliderElement => {
    new Slider(sliderElement as HTMLElement);
  });
}

function initBreadcrumbs() {
  const breadcrumbElements = document.querySelectorAll(`[${BREADCRUMB_CONTAINER}]`);

  breadcrumbElements.forEach(breadcrumbElement => {
    new Breadcrumb(breadcrumbElement as HTMLElement);
  });
}

(() => {
  initVideoModals();
  initSliders();
  initBreadcrumbs();
})();
