import { Modal } from 'bootstrap';

type EventListenerParams = Parameters<Document['addEventListener']>;

export const VIDEO_MODAL_DATA_TOGGLE = 'data-napier-video';

export class VideoModal {
  private videoUrl = '';
  private videoLabel = '';
  private videoTitle: string | undefined;
  private videoModalElement: HTMLDivElement | undefined;
  private videoModal: Modal | undefined;
  private videoModalCloseElement: HTMLButtonElement | undefined;
  private openHandler!: EventListenerParams[1];
  private closeHandler!: EventListenerParams[1];
  private removable = true;

  constructor(videoToggleElement: HTMLElement) {
    this.openHandler = this.openModal.bind(this);
    this.closeHandler = this.closeModal.bind(this);

    if (videoToggleElement != null) {
      this.videoUrl = videoToggleElement.dataset['napierVideo'] ?? '';
      this.videoLabel = videoToggleElement.dataset['napierVideoLabel'] ?? '';
      this.videoTitle = videoToggleElement.dataset['napierVideoTitle'] ?? undefined;

      videoToggleElement.addEventListener('click', this.openHandler);
    }
  }

  private openModal(): void {
    this.removable = false;

    if (this.videoModal == null && this.videoModalElement == null) {
      this.videoModalElement = this.generateModal();
      this.videoModal = new Modal(this.videoModalElement, { backdrop: true, keyboard: true, focus: true });
    }

    this.videoModal?.show();
  }

  private closeModal(): void {
    this.videoModal?.hide();
    this.removable = true;

    setTimeout(() => {
      if (this.removable === true) {
        this.videoModalCloseElement?.removeEventListener('click', this.closeHandler);
        this.videoModal?.dispose();
        this.videoModalElement?.remove();

        this.videoModal = undefined;
        this.videoModalElement = undefined;
      }
    }, 500);
  }

  private generateModal(): HTMLDivElement {
    // Create container
    const modalContainer = document.createElement('div');

    modalContainer.classList.add('modal', 'fade');
    modalContainer.ariaRoleDescription = 'dialog';
    modalContainer.tabIndex = -1;
    modalContainer.ariaModal = 'true';
    modalContainer.ariaLabel = this.videoLabel;

    // Create dialog
    const modalDialog = document.createElement('div');

    modalDialog.classList.add('modal-dialog', 'modal-lg', 'modal-dialog-centered');
    modalContainer.appendChild(modalDialog);

    // Create content
    const modalContent = document.createElement('div');

    modalContent.classList.add('modal-content');
    modalDialog.appendChild(modalContent);

    // Create header
    const modalHeader = document.createElement('div');
    const closeButton = document.createElement('button');
    const closeButtonLabel = document.createElement('span');
    const closeButtonInner = document.createElement('div');
    const closeButtonSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const closeButtonSvgUse = document.createElementNS('http://www.w3.org/2000/svg', 'use');

    modalHeader.classList.add('modal-header');
    closeButton.type = 'button';
    closeButton.classList.add('close-btn');
    closeButton.title = 'close video modal';
    closeButtonLabel.textContent = 'close';
    closeButtonInner.classList.add('close-button-inner-element');
    closeButtonInner.ariaHidden = 'true';
    closeButtonSvg.setAttribute('viewBox', '0 0 38 37');
    closeButtonSvg.setAttribute('focusable', 'false');
    closeButtonSvgUse.setAttribute('href', '#napier-close-cross-icon');

    modalContent.appendChild(modalHeader);
    modalHeader.appendChild(closeButton);
    closeButton.appendChild(closeButtonLabel);
    closeButton.appendChild(closeButtonInner);
    closeButtonInner.appendChild(closeButtonSvg);
    closeButtonSvg.appendChild(closeButtonSvgUse);

    // Add close handler
    this.videoModalCloseElement = closeButton;
    this.videoModalCloseElement.addEventListener('click', this.closeHandler);

    // Create body
    const modalBody = document.createElement('div');
    const videoContainer = document.createElement('div');
    const videoElement = document.createElement('iframe');

    modalBody.classList.add('modal-body');
    videoContainer.classList.add('ratio', 'ratio-16x9');
    videoElement.src = this.videoUrl;
    videoElement.allowFullscreen = true;

    if (this.videoTitle != null) {
      videoElement.title = this.videoTitle;
    }

    modalContent.appendChild(modalBody);
    modalBody.appendChild(videoContainer);
    videoContainer.appendChild(videoElement);

    // Append modal to document
    document.body.appendChild(modalContainer);

    return modalContainer;
  }
}
